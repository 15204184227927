import React, {useEffect} from "react";
import Img from "gatsby-image/withIEPolyfill"
import * as Markdown from 'react-markdown';
import * as style from "../../components/Content/TwoColumnContent.module.css";

const TwoColumnContent = ({ data, align, ...props }) => {

    useEffect(
        () => {
            const hrefs = document.getElementsByTagName('A');
            for(let val of Object.values(hrefs)) {
                if(val.getAttribute('title')) {
                    val.setAttribute('href', val.getAttribute('title'))
                    val.setAttribute('data-uk-scroll', '')
                } else {
                    val.setAttribute('target', '_blank')
                }
            }

        }, []
    )

    return (
        <section id={data.slug} className={`${style.twoColumnContent} uk-section`}>
            <div className={`uk-container uk-container-small`}>
                <div className="uk-grid-large uk-grid">
                    <div className={`uk-display-inline-block uk-flex uk-width-3-5@m ${align ? "uk-flex-right" : "uk-flex-left"}`}>
                        <div className="">
                            {data.media.file.contentType.split('/')[0] === "image" ?
                            <Img
                                fluid={data.media.fluid}
                                alt={data.slug}
                                className={`uk-border-rounded ${style.twoColumnContentMedia}`}
                                objectFit="cover"
                                objectPosition="top center"
                                />
                            :
                            <video className="uk-video" controls autoPlay muted loop>
                                <source src={data.media.file.url} type="video/mp4" />
                                Your browser does not support the HTML5 Video element.
                            </video>
                            }
                        </div>
                    </div>
                    <div className={`uk-width-expand uk-flex uk-flex-middle ${align && "uk-flex-first@m"} uk-first-column`}>
                        <Markdown
                            source={data.content.childMarkdownRemark.html}
                            escapeHtml={false}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TwoColumnContent;
