import React from "react";
import { graphql } from 'gatsby';
import BackgroundImage from "gatsby-background-image";
import { Helmet } from "react-helmet";
import Layout from "../../Layout";
import { BigHeadline } from "../../components/Content";
import TwoColumnContent from "../../components/Products/TwoColumnContent";
import * as style from "../../components/Content/Content.module.css";

const description = "Need to travel, but don't know where it's safe or if borders are open? Here's a little app to help."
const keywords = "travel, lockdown, quarantine, advisory, visa, immigration, sea, asia, southeast asia, covid19, covid-19, coronavirus, wfh, working from anywhere, business, covid, flights, airlines"


const Travel = ({ data, location, ...props }) => {

    const {
        heroImage,
        // mainImage,
        title,
        slug,
        contentComponents: entries,
        // wayfareProduct,
        // wayfareScreens
    } = data.contentfulProducts

    const ogImg = `https://michaellisboa.com${data.wayfareScreens.publicURL}`;

    return (
        <Layout location={location}>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
                <meta name="twitter:description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta name="og:keywords" content={keywords} />
                <meta name="twitter:keywords" content={keywords} />
                <meta name="image" content={ogImg} />
                <meta property="og:image" content={ogImg} />
                <meta name="twitter:image" content={ogImg} />
            </Helmet>
            <section style={{ overflowX: "hidden" }} className="uk-section uk-section-large">
                <div className={`uk-container uk-container-small uk-height-large uk-flex uk-flex-center uk-flex-middle`}>
                    <div className="uk-padding uk-width-4-5@m">
                        <div className="uk-width-1-1 uk-width-3-4@m">
                            <div className="uk-width-1-1 uk-width-auto@s">
                                <h1 className="h1__xlarge uk-margin-remove-bottom uk-margin-remove-adjacent">Problem solved.</h1>
                            </div>
                            <p className="uk-text-lead uk-margin-top">I couldn't find simple solution for travel information in a Covid world.</p><p className="uk-text-lead">So, I made it.</p>
                        </div>
                    </div>
                </div>
            </section>
            <BackgroundImage
                Tag="div"
                id={slug}
                fluid={heroImage.fluid}
                alt={heroImage.title}
                preserveStackingContext={true}
                className={`${style.heroImage}`}
                style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: `center center`,
                    height: "100%"
                }}
            />
            <div className="uk-container uk-container-small uk-margin uk-flex uk-flex-center">
                <small style={{ fontSize: "12px" }} className="uk-text-small uk-margin-remove-vertical">A simple search to help people travel in a Covid world</small>
            </div>
            <div>
                {entries.map((block, i) =>
                    <TwoColumnContent
                        key={`block${i}`}
                        data={block}
                        align={i % 2 === 0}
                    />
                )}
            </div>
        </Layout>
    )
}
export default Travel;

export const travelQuery = graphql`
    query travelQuery {
        contentfulProducts(slug: {
            eq: "travel-safe-by-michael"
        }) {
            title
            slug
            mainImage {
                fluid(maxWidth: 800) {
                  ...GatsbyContentfulFluid_withWebp
                }
                title
            }
            heroImage {
                fluid(maxWidth: 1600) {
                  ...GatsbyContentfulFluid_withWebp
                }
                title
            }
            contentComponents {
              slug
              textAlign
              content {
                childMarkdownRemark {
                  html
                }
              }
              media {
                file {
                  contentType
                  url
                }
                localFile {
                  publicURL
                }
                fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp
                }
              }
            }
        }
        wayfareScreens: file(relativePath: { eq: "og-travel.png" }) {
            name
            publicURL
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
