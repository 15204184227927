import React from "react";

export const renderTitle = string =>
    string.split("\n").map((text, index) => (
        <span key={`${text}-${index}`} className="uk-margin-remove uk-width-1-1">
            {text}
            <br />
        </span>
    ));

export const PageHeader = ({ children, ...props }) =>
    <section style={{ overflowX: "hidden" }} className="uk-section uk-section-large">
        <div className={`uk-container uk-container-small uk-height-large uk-flex uk-flex-center uk-flex-middle`}>
            <div className="uk-padding uk-width-4-5@m">
                <div className="uk-width-1-1 uk-width-3-4@m">
                    <h1 className="h1__large uk-margin-bottom">{renderTitle(props.title)}</h1>
                    <p className="uk-text-lead uk-margin-remove-vertical">{renderTitle(props.subtitle)}</p>
                    {children}
                </div>
            </div>
        </div>
    </section>

export const BigHeadline = ({ children, ...props }) =>
    <section className="uk-section">
        <div className="uk-container uk-container-small">
            <div className="uk-width-1-1 uk-width-4-5@m">
                <div className="uk-width-1-1 uk-width-auto@s">
                    <h1 className="h1__large uk-margin-bottom uk-margin-remove-adjacent">{renderTitle(props.title)}</h1>
                    <p className="uk-text-lead uk-margin-remove-vertical">{renderTitle(props.subtitle)}</p>
                    {children}
                </div>
            </div>
        </div>
    </section>
